import React from "react";

import ContextProvider from "../providers/contextProvider";
import CookieBanner from "../components/cookieBanner/index";

export default ({ element }) => (
  <>
    <ContextProvider>{element}</ContextProvider>
    <CookieBanner />
  </>
);

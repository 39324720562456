// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-products-page-js": () => import("/drone/src/src/templates/product/productsPage.js" /* webpackChunkName: "component---src-templates-product-products-page-js" */),
  "component---src-templates-product-detail-product-detail-page-js": () => import("/drone/src/src/templates/productDetail/productDetailPage.js" /* webpackChunkName: "component---src-templates-product-detail-product-detail-page-js" */),
  "component---src-templates-category-category-page-js": () => import("/drone/src/src/templates/category/categoryPage.js" /* webpackChunkName: "component---src-templates-category-category-page-js" */),
  "component---src-components-redirect-js": () => import("/drone/src/src/components/redirect.js" /* webpackChunkName: "component---src-components-redirect-js" */),
  "component---src-pages-wrap-root-element-js": () => import("/drone/src/src/pages/wrapRootElement.js" /* webpackChunkName: "component---src-pages-wrap-root-element-js" */)
}


import React from "react";
import CookieConsent from "react-cookie-consent";
require("./bannerStyle.css");

export default () => (
  <CookieConsent
    flipButtons
    disableStyles
    enableDeclineButton
    location="top"
    buttonText="Akzeptieren"
    declineButtonText="Ablehnen"
    containerClasses="cookieConsent"
    contentClasses="cookieConsent--content"
    buttonWrapperClasses="cookieConsent--buttonWrapper"
    cookieName="gatsby-gdpr-google-tagmanager"
    expires={150}
  >
    <p id="wording">
      Diese Website speichert Cookies auf Ihrem Computer. Diese Cookies werden
      verwendet, um die Website zu optimieren und Ihnen einen persönlich auf Sie
      zugeschnittenen Service bereitstellen zu können, sowohl auf dieser Website
      als auch auf anderen Medienkanälen. Erfahren Sie mehr über die von uns
      eingesetzten Cookies in unserer Datenschutzrichtlinie.
    </p>
    <p id="disclaimer">
      Bei Ihrem Besuch auf unserer Seite werden Ihre Daten nicht verfolgt. Um
      Ihren Wünschen und Einstellungen allerdings optimal zu entsprechen, müssen
      wir nur minimale Cookies setzen, damit Sie diese Auswahl nicht noch einmal
      treffen müssen.
    </p>
  </CookieConsent>
);

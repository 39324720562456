import React from "react";
import WebshopContext from "../providers/WebshopProvider";
class ContextWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: null,
      products: {},
      productDetail: {},
      allProducts: null,
      loaded: false,
      productPrices: {},
    };
    this.setProducts = this.setProducts.bind(this);
    this.setProductDetailPrice = this.setProductDetailPrice.bind(this);
  }

  componentDidMount() {

 



    if (!this.state.loaded) {
      this.setState({ loaded: true });
      this.setState({ loaded: 1 });
      fetch(process.env.GATSBY_ENV +
        "/api/v1/products/categories?buyerGroupId=" +
          process.env.GATSBY_BUYER_GROUP
      )
        .then(response => response.json()) // parse JSON from request
        .then(result => {
          this.setState({ categories: result });
        })
        .then(() =>
          fetch(process.env.GATSBY_ENV +
            "/api/v3/webshopProducts/?buyerGroupId=" +
              process.env.GATSBY_BUYER_GROUP
          )
        )

        .then(response => response.json()) // parse JSON from request
        .then(result => {
          this.setState({ allProducts: result });
        });
    }
  }

  setProducts(productId) {
    if (!(productId in this.state.products)) {
      fetch(
        process.env.GATSBY_ENV +
        "/api/v3/webshopProducts/" +
          productId +
          "?buyerGroupId=" +
          process.env.GATSBY_BUYER_GROUP
      )
        .then(response => response.json()) // parse JSON from request
        .then(result => {
          let products = this.state.products;
          products[productId] = result;

          this.setState({ products: products });
        });
    }
  }

  setProductDetailPrice(productId) {
    if (!(productId in this.state.productPrices)) {
      fetch(
        process.env.GATSBY_ENV +
        "/api/v3/webshopProductDetailPrices/" +
          productId +
          "?buyerGroupId=" +
          process.env.GATSBY_BUYER_GROUP
      )
        .then(response => response.json()) // parse JSON from request
        .then(result => {
          let productPrices = this.state.productPrices;
          productPrices[productId] = result;
          this.setState({ productPrices: productPrices });
        });
    }
  }

  render() {
    return (
      <WebshopContext.Provider
        value={{
          allProducts: this.state.allProducts,
          categories: this.state.categories,
          setProducts: this.setProducts,
          products: this.state.products,
          setProductDetail: this.setProductDetail,
          setProductDetailPrice: this.setProductDetailPrice,
          productPrices: this.state.productPrices,
        }}
      >
        {this.props.children}
      </WebshopContext.Provider>
    );
  }
}

export default ContextWrapper;
